import React from "react";
import PropTypes from "prop-types";
import styled from "styled-components/macro";

const SegmentControlContainer = styled.div`
  display: flex;
`;

const SegmentControl = ({ children }) => {
  return <SegmentControlContainer>{children}</SegmentControlContainer>;
};

SegmentControl.propTypes = {
  children: PropTypes.node,
};

export default SegmentControl;
