import styled, { css } from "styled-components";
import Colors from "./colorswatches";

const Text = styled.span`
  font-family: "Metropolis";
  ${(props) => {
    if (!props.inheritColor) {
      return css`
        color: ${(props) => props.color || Colors.darkGrey100};
      `;
    }
  }}

  text-transform: ${(props) => (props.uppercase ? "uppercase" : "none")};
  white-space: ${(props) => (props.noWrap ? "nowrap" : "normal")};

  ${(props) => {
    switch (props.type) {
      case "extra-large-semibold":
        return css`
          font-size: 32px;
          font-weight: 600;
        `;

      case "large-semibold":
        return css`
          font-size: 24px;
          font-weight: 600;
        `;

      case "extra-big-medium":
        return css`
          font-size: 20px;
          font-weight: 500;
        `;

      case "big-medium":
        return css`
          font-size: 16px;
          font-weight: 500;
        `;

      case "regular-bold":
        return css`
          font-size: 16px;
          font-weight: 700;
        `;

      case "regular-medium":
        return css`
          font-size: 16px;
          font-weight: 500;
        `;

      case "regular-medium-caps":
        return css`
          font-size: 16px;
          font-weight: 500;
          line-height: 16px;
          text-transform: uppercase;
        `;

      case "regular-medium-underlined":
        return css`
          font-size: 16px;
          font-weight: 500;
          text-decoration: underline;
        `;

      case "regular-light":
        return css`
          font-size: 16px;
          font-weight: 200;
          line-height: 22.4px;
        `;

      case "small-medium":
        return css`
          font-size: 14px;
          font-weight: 500;
        `;

      case "small-medium-underlined":
        return css`
          font-size: 14px;
          font-weight: 500;
          text-decoration: underline;
        `;

      case "small-light":
        return css`
          font-size: 14px;
          font-weight: 200;
        `;

      case "extra-small-medium":
        return css`
          font-size: 12px;
          font-weight: 500;
          line-height: 16.8px;
        `;
    }
  }};
`;

export default Text;
