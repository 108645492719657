import React from "react";
import PropTypes from "prop-types";

function OdcIcon({ name, className, color = "none", size = 20 }) {
  function viewBox() {
    switch (name) {
      case "close":
        return "0 0 26 26";
      case "arrow-right":
        return "0 0 21 16";
      default:
        return "";
    }
  }

  function path() {
    switch (name) {
      case "close":
        return (
          <path d="M22.855 1.38253L13 11.2375L3.14503 1.38253C2.908 1.16563 2.59642 1.04854 2.27521 1.05565C1.954 1.06277 1.64792 1.19355 1.42073 1.42073C1.19354 1.64792 1.06277 1.954 1.05565 2.27521C1.04854 2.59643 1.16562 2.908 1.38253 3.14503L11.2325 13L1.38003 22.8525C1.25966 22.9672 1.16343 23.1049 1.09701 23.2573C1.0306 23.4097 0.995319 23.5739 0.99326 23.7402C0.991201 23.9064 1.0224 24.0714 1.08502 24.2255C1.14765 24.3795 1.24043 24.5194 1.35792 24.6371C1.47541 24.7548 1.61524 24.8477 1.76918 24.9106C1.92313 24.9734 2.08808 25.0049 2.25434 25.003C2.42061 25.0012 2.58483 24.9662 2.73736 24.9C2.88989 24.8338 3.02764 24.7377 3.14253 24.6175L13 14.765L22.855 24.62C23.0921 24.8369 23.4036 24.954 23.7248 24.9469C24.0461 24.9398 24.3521 24.809 24.5793 24.5818C24.8065 24.3546 24.9373 24.0486 24.9444 23.7273C24.9515 23.4061 24.8344 23.0946 24.6175 22.8575L14.7625 13.0025L24.6175 3.14503C24.7379 3.03032 24.8341 2.8927 24.9005 2.74026C24.967 2.58783 25.0022 2.42366 25.0043 2.25739C25.0064 2.09113 24.9752 1.92614 24.9125 1.7721C24.8499 1.61807 24.7571 1.47811 24.6396 1.36046C24.5221 1.2428 24.3823 1.14981 24.2284 1.08697C24.0744 1.02413 23.9095 0.992697 23.7432 0.994521C23.5769 0.996344 23.4127 1.03139 23.2602 1.09759C23.1077 1.16379 22.9699 1.25982 22.855 1.38003V1.38253Z" />
        );
      case "arrow-right":
        return (
          <path d="M20.7071 8.70711C21.0976 8.31658 21.0976 7.68342 20.7071 7.29289L14.3431 0.928932C13.9526 0.538408 13.3195 0.538408 12.9289 0.928932C12.5384 1.31946 12.5384 1.95262 12.9289 2.34315L18.5858 8L12.9289 13.6569C12.5384 14.0474 12.5384 14.6805 12.9289 15.0711C13.3195 15.4616 13.9526 15.4616 14.3431 15.0711L20.7071 8.70711ZM0 9H20V7H0V9Z" />
        );
      default:
        return "";
    }
  }

  return (
    <svg
      className={`${className}`}
      viewBox={viewBox()}
      fill={color}
      xmlns="http://www.w3.org/2000/svg"
      height={size}
      width={size}
    >
      {path()}
    </svg>
  );
}

OdcIcon.defaultProps = {
  className: "",
};

OdcIcon.propTypes = {
  name: PropTypes.string.isRequired,
  className: PropTypes.string,
  color: PropTypes.string,
  size: PropTypes.number,
};

export default OdcIcon;
