import React, { useState } from "react";
import PropTypes from "prop-types";
import styled from "styled-components/macro";
import { Icon } from "@iconify/react";
import Colors from "./colorswatches";
import Text from "./text";

const HintIcon = styled(Icon)`
  cursor: pointer;
  z-index: 10;
`;

const PopUp = styled.div`
  display: none;
  position: absolute;
  transform: translateY(calc(-100% - 10px));
  left: 20px;
  background-color: ${Colors.white};
  border-radius: 3px;
  color: white;
  font-size: 14px;
  padding: 10px;
  width: 180px;
  border-radius: 10px 10px 10px 0;

  border: 1px solid ${Colors.lightGrey200};
  box-shadow: 0 0 8px ${Colors.darkGrey300o20};
  z-index: -1;

  &.visible {
    display: block;
    z-index: 10;
  }
`;

const HintContainer = styled.span`
  position: relative;
`;

function Hint({ className, children }) {
  const [popUpVisible, setPopUpVisible] = useState(false);

  return (
    <HintContainer className={className}>
      <HintIcon
        icon="ant-design:info-circle-outlined"
        width="18px"
        height="18px"
        onMouseEnter={() => setPopUpVisible(true)}
        onMouseLeave={() => setPopUpVisible(false)}
        color={Colors.darkGrey300}
      />
      <PopUp className={`pop-up ${popUpVisible ? "visible" : ""}`}>
        <Text type="extra-small-medium" color={Colors.darkGrey400}>
          {children}
        </Text>
      </PopUp>
    </HintContainer>
  );
}

Hint.propTypes = {
  className: PropTypes.string,
  children: PropTypes.node,
};

export default Hint;
