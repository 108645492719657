import React from "react";
import PropTypes from "prop-types";
import styled, { css } from "styled-components";
import Colors from "./colorswatches";
import DatePicker from "react-datepicker";

const StyledGenericInput = styled.input`
  ${(props) => {
    let styling = `
      font-family: "Metropolis";
    `;
    switch (props.type) {
      case "text":
        styling += `
          display: block;
          box-sizing: border-box;
          width: 100%;
          border: 1px solid ${Colors.lightGrey200};
          padding: 10px;
          background: ${Colors.lightGrey100};
        `;
        break;
      case "email":
        styling += `
          display: block;
          box-sizing: border-box;
          width: 100%;
          border: 1px solid ${Colors.lightGrey200};
          padding: 10px;
          background: ${Colors.lightGrey100};
        `;
        break;
      case "password":
        styling += `
          display: block;
          box-sizing: border-box;
          width: 100%;
          border: 1px solid ${Colors.lightGrey200};
          padding: 10px;
          background: ${Colors.lightGrey100};
        `;
        break;
      case "number":
        styling += `
          display: block;
          box-sizing: border-box;
          width: 100%;
          border: 1px solid ${Colors.lightGrey200};
          padding: 10px;
          background: ${Colors.lightGrey100};
        `;
        break;
    }

    if (props.error) {
      styling += `
        border: 1px solid ${Colors.red300};
      `;
    }

    if (props.disabled) {
      styling += `
        background: ${Colors.white};
      `;
    }

    return css`
      ${styling}
    `;
  }}
`;

const StyledDatePicker = styled(DatePicker)`
  ${(props) => {
    let styling = `
      display: block;
      box-sizing: border-box;
      width: 100%;
      border: 1px solid ${Colors.lightGrey200};
      padding: 10px;
      background: ${Colors.lightGrey100};
      `;

    if (props.disabled) {
      styling += `
        background: ${Colors.white};
      `;
    }

    return css`
      ${styling}
    `;
  }}
`;

function Input(props) {
  const { type } = props;

  if (type === "date") {
    return <StyledDatePicker {...props} />;
  }

  return <StyledGenericInput {...props} />;
}

export default Input;

Input.propTypes = {
  type: PropTypes.string.isRequired,
};
