import styled from "styled-components";
import Colors from "./colorswatches";
import { Frame } from "@bitesite/react-figstrap";

const Card = styled(Frame)`
  border-width: 1px;
  border-style: solid;
  border-color: ${Colors.lightGrey200};
  border-radius: 10px;
`;

export default Card;
