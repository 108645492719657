import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import {
  CarouselProvider,
  Slider,
  Slide,
  Dot,
  ButtonNext,
  ButtonBack,
} from "pure-react-carousel";
import "pure-react-carousel/dist/react-carousel.es.css";
import styled from "styled-components/macro";
import COLORS from "./colorswatches";
import Constants from "./constants";
import { Icon } from "@iconify/react";

const SlideImage = styled.img`
  width: 100%;
  height: 100%;
  object-fit: contain;
`;

const CarouselContainer = styled.div`
  .carousel__dot {
    height: 10px;
    width: 10px;
    border-radius: 5px;
    background-color: ${COLORS.lightGrey200};
    border: none;
    padding: 0;
  }

  .carousel__dot--selected {
    background-color: ${COLORS.darkGrey300};
  }
`;

const DotsContainer = styled.div`
  display: flex;
  gap: 15px;
  justify-content: center;

  @media ${Constants.style.mobileAndDownMediaQuery} {
    display: none;
  }
`;

const NextPrevContainer = styled.div`
  display: none;
  gap: 40px;
  justify-content: center;

  .carousel__next-button,
  .carousel__back-button {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 50px;
    width: 50px;
    border-radius: 25px;
    background-color: ${COLORS.darkGrey500};
    border: none;
    padding: 0;

    &:disabled {
      background-color: ${COLORS.lightGrey100};
    }
  }

  @media ${Constants.style.mobileAndDownMediaQuery} {
    display: flex;
  }
`;

const Carousel = ({ slideContents, onSlideDotClicked }) => {
  const [selectedIndex, setSelectedIndex] = useState(0);

  useEffect(() => {
    if (onSlideDotClicked) {
      onSlideDotClicked(selectedIndex);
    }
  }, [selectedIndex]);

  return (
    <CarouselContainer>
      <CarouselProvider
        naturalSlideWidth={100}
        naturalSlideHeight={70}
        totalSlides={slideContents.length}
        touchEnabled={false}
        dragEnabled={false}
      >
        <Slider>
          {slideContents.map((slideContent, index) => {
            return (
              <Slide key={index} index={index}>
                <SlideImage src={slideContent.image} />
              </Slide>
            );
          })}
        </Slider>
        <DotsContainer>
          {slideContents.map((_, index) => {
            return (
              <Dot
                key={index}
                slide={index}
                onClick={() => {
                  setSelectedIndex(index);
                }}
              />
            );
          })}
        </DotsContainer>
        <NextPrevContainer>
          <ButtonBack>
            <Icon
              icon="ic:round-chevron-left"
              color={COLORS.white}
              height={40}
              onClick={() => {
                setSelectedIndex((prevIndex) =>
                  prevIndex === 0 ? 0 : prevIndex - 1
                );
              }}
            />
          </ButtonBack>
          <ButtonNext>
            <Icon
              icon="ic:round-chevron-right"
              color={COLORS.white}
              height={40}
              onClick={() => {
                setSelectedIndex((prevIndex) =>
                  prevIndex === slideContents.length - 1
                    ? slideContents - 1
                    : prevIndex + 1
                );
              }}
            />
          </ButtonNext>
        </NextPrevContainer>
      </CarouselProvider>
    </CarouselContainer>
  );
};

Carousel.propTypes = {
  slideContents: PropTypes.array.isRequired,
  onSlideDotClicked: PropTypes.func,
};

export default Carousel;
