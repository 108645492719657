import React, { useState } from "react";
import Input from "./input";
import styled from "styled-components/macro";

const DollarInputFieldContainer = styled.div`
  display: inline-block;
`;

const StyledInput = styled(Input)`
  padding-left: 20px !important;
  &:focus-visible::placeholder {
    color: transparent;
  }

  &:not(:placeholder-shown) {
    padding-left: 20px;
  }
`;

function DollarInputField(props) {
  const [hasFocus, setHasFocus] = useState(false);

  return (
    <DollarInputFieldContainer style={props.style}>
      <StyledInput
        {...props}
        onFocus={() => setHasFocus(true)}
        onBlur={() => {
          setHasFocus(false);
        }}
        type="number"
      />
      {hasFocus && <i className="dollar-sign">$</i>}
    </DollarInputFieldContainer>
  );
}

export default DollarInputField;
