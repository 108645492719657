import React from "react";
import styled from "styled-components";
import Colors from "./colorswatches";

const LabelWrapper = styled.label`
  width: 100%;
  display: block;
  font-size: 16px;
  color: ${(props) => {
    if (props.darkMode) {
      return Colors.white;
    } else {
      return Colors.darkGrey100;
    }
  }};

  &.checkbox-label {
    display: inline;
  }

  &.invisible-label {
    opacity: 0;
  }

  input {
    margin-top: 10px;
  }

  ${(props) => {
    if (props.errorMessage) {
      return `
        input {
          border: 2px solid ${Colors.red300} !important;
          border-radius: 3px;
        }
      `;
    }
  }}
`;

const ErrorMessageWrapper = styled.p`
  font-size: 14px;
  color: ${Colors.red300};
  margin-top: 4px;
`;

function Label(props) {
  return (
    <LabelWrapper
      className={props.className}
      htmlFor={props.htmlFor}
      style={props.style}
      errorMessage={props.errorMessage}
      darkMode={props.darkMode}
    >
      {props.children}

      {props.errorMessage && (
        <ErrorMessageWrapper>{props.errorMessage}</ErrorMessageWrapper>
      )}
    </LabelWrapper>
  );
}

export default Label;
