import React from "react";
import styled from "styled-components/macro";
import { Icon } from "@iconify/react";
import Colors from "./colorswatches";

const CheckboxContainer = styled.div`
  display: inline-block;
  vertical-align: middle;
  margin-right: 5px;
  padding: 6px;
  width: 20px;
  height: 20px;
  &:hover {
    border-radius: 30px;
    background-color: ${Colors.gold50};
  }
`;

// Hide checkbox visually but remain accessible to screen readers.
// Source: https://polished.js.org/docs/#hidevisually
const HiddenCheckbox = styled.input.attrs({ type: "checkbox" })`
  border: 0;
  clip: rect(0 0 0 0);
  height: 1px;
  margin: -1px;
  overflow: hidden;
  padding: 0;
  position: absolute;
  white-space: nowrap;
  width: 1px;
`;

const StyledCheckbox = styled.div`
  display: inline-block;
  height: 16px;
`;

const StyledCheckIcon = styled(Icon)`
  display: ${(props) => (props.value ? "block" : "none")};
  font-size: 20px;

  color: ${(props) => {
    if (props.darkMode) {
      if (props.selectedIcon) {
        return Colors.gold100;
      } else {
        return Colors.white;
      }
    } else {
      if (props.selectedIcon) {
        return Colors.darkGrey300;
      } else {
        return Colors.darkGrey300;
      }
    }
  }};
`;

const Checkbox = ({ className, value, darkMode, ...props }) => (
  <CheckboxContainer className={className}>
    <HiddenCheckbox value={value} {...props} />
    <StyledCheckbox value={value} darkMode={darkMode}>
      <StyledCheckIcon
        icon="mdi:checkbox-marked"
        value={value}
        selectedIcon
        darkMode={darkMode}
      />

      <StyledCheckIcon
        icon="mdi:checkbox-blank-outline"
        value={!value}
        darkMode={darkMode}
      />
    </StyledCheckbox>
  </CheckboxContainer>
);

export default Checkbox;
