const Colors = {
  darkGrey100: "#191919",
  darkGrey200: "#292929",
  darkGrey300: "#4B4F54",
  darkGrey300o20: "rgb(75, 79, 84, 0.2)",
  darkGrey400: "#707070",
  darkGrey500: "#9e9e9e",
  lightGrey200: "#D6D6D6",
  lightGrey100: "#F5F5F5",
  white: "#ffffff",

  gold100o30: "rgba(178, 150, 108, 0.3)",
  gold50: "#d5c5af",
  gold100: "#B2966C",
  gold200: "#93784D",

  green50: "#e6f5f4",
  green100o40: "rgba(1, 156, 149, 0.4)",
  green100: "#019C95",
  green200: "#017973",
  red300: "#9A2D23",
  red200: "#DB695F",
  red200o70: "rgba(219, 105, 95, 0.7)",
  red200o40: "rgba(219, 105, 95, 0.4)",
  red100: "#F4D2CF",

  blue100: "#4267EB",
  blue200: "#1337B9",
};

export default Colors;
