import React, { useEffect } from "react";
import PropTypes from "prop-types";
import styled from "styled-components/macro";
import Colors from "./colorswatches";

const ModalDialogBackground = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.25);
  overflow: scroll;
  z-index: 1000;
`;

const ModalDialogBody = styled.div`
  background: ${(props) =>
    props.darkMode ? Colors.darkGrey200 : Colors.white};
  border-radius: 10px;
  color: ${(props) => (props.darkMode ? Colors.white : Colors.darkGrey200)};

  margin: 20px auto;
  margin-top: ${(props) => (props.marginTop ? `${props.marginTop}px` : "20px")};
  max-width: ${(props) => (props.maxWidth ? `${props.maxWidth}px` : "800px")};
  width: calc(100% - 40px);
  box-sizing: border-box;

  .modal-header {
    text-transform: uppercase;
  }

  .component-close-icon.close-button {
    height: 14px;
    width: 20px;
    float: right;
    cursor: pointer;

    path {
      fill: ${(props) =>
        props.darkMode ? Colors.lightGrey100 : Colors.darkGrey200};
    }
  }

  .icon-and-content {
    display: flex;
    padding: 14px 0;

    .icon-container {
      width: 50px;
      text-align: center;
    }

    .content-container {
    }
  }
`;

function ModalDialog({
  children,
  maxWidth,
  marginTop,
  modalDialogClassName,
  darkMode,
}) {
  useEffect(() => {
    document.body.style.overflow = "hidden";

    return () => {
      document.body.style.overflow = "auto";
    };
  }, []);

  return (
    <ModalDialogBackground>
      <ModalDialogBody
        maxWidth={maxWidth}
        marginTop={marginTop}
        className={modalDialogClassName}
        darkMode={darkMode}
      >
        {children}
      </ModalDialogBody>
    </ModalDialogBackground>
  );
}

ModalDialog.defaultProps = {
  children: null,
  modalDialogClassName: "",
  darkMode: false,
};

ModalDialog.propTypes = {
  children: PropTypes.array,
  modalDialogClassName: PropTypes.string,
  maxWidth: PropTypes.number,
  marginTop: PropTypes.number,
  darkMode: PropTypes.bool,
};

export default ModalDialog;
