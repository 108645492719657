import React, { useEffect } from "react";
import PropTypes from "prop-types";
import styled from "styled-components/macro";
import Notice from "./notice";

const ResultModalDialogBackground = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.25);
  overflow: scroll;
  z-index: 1000;
`;

function ResultModalDialog(props) {
  useEffect(() => {
    document.body.style.overflow = "hidden";

    return () => {
      document.body.style.overflow = "auto";
    };
  }, []);

  return (
    <ResultModalDialogBackground>
      <Notice
        {...props}
        maxWidth={props.maxWidth || 528}
        marginTop={props.marginTop || 40}
      />
    </ResultModalDialogBackground>
  );
}

ResultModalDialog.defaultProps = {
  headerText: "Result",
};

ResultModalDialog.propTypes = {
  type: PropTypes.string.isRequired,
  onClose: PropTypes.func.isRequired,
  headerText: PropTypes.string,
  messages: PropTypes.array,
  subMessageText: PropTypes.string,
  resultCodeText: PropTypes.string,
  renderActions: PropTypes.func.isRequired,
  maxWidth: PropTypes.number,
  marginTop: PropTypes.number,
};

export default ResultModalDialog;
