import React from "react";
import PropTypes from "prop-types";
import styled, { css } from "styled-components/macro";
import Colors from "./colorswatches";
import Text from "./text";
import OdcIcon from "./odc_icon";
import { Icon } from "@iconify/react";

const NoticeContainer = styled.div`
  ${(props) => {
    switch (props.type) {
      case "error":
        return css`
          border: 1px solid ${Colors.red300};
          background: ${Colors.red100};
        `;
      case "success":
        return css`
          border: 1px solid ${Colors.green200};
          background: ${Colors.green50};
        `;
    }
  }}
  color: ${Colors.darkGrey100};
  border-radius: 4px;

  margin: 20px auto;
  margin-top: ${(props) => (props.marginTop ? `${props.marginTop}px` : "0px")};

  box-sizing: border-box;
  width: 100%;
  max-width: ${(props) => (props.maxWidth ? `${props.maxWidth}px` : "100%")};

  .close-icon {
    height: 12px;
    width: 12px;
    float: right;
    cursor: pointer;
    margin-top: 17px;
    margin-right: 24px;

    path {
      fill: ${Colors.darkGrey300};
    }
  }
`;

const IconAndContent = styled.div`
  display: flex;
  padding: 14px 0;
`;

const IconContainer = styled.div`
  width: 30px;
  text-align: center;
  ${(props) => {
    switch (props.type) {
      case "error":
        return css`
          color: ${Colors.red300};
        `;
      case "success":
        return css`
          color: ${Colors.green200};
        `;
    }
  }}
  margin: 0 10px;

  svg {
    font-size: 24px;
    margin-top: -4px;
  }
`;

const Messages = styled.ul`
  margin: 0;
`;

const ContentContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;
  padding: 0 16px;
`;

const ModalHeader = styled.div`
  font-size: 16px;
`;

const SubMessage = styled.div``;

function Notice({
  type,
  onClose,
  headerText,
  messages,
  subMessageText,
  resultCodeText,
  renderActions,
  maxWidth,
  marginTop,
}) {
  function handleOnCloseClick(e) {
    e.preventDefault();
    if (onClose) {
      onClose();
    }
  }

  function headerColor() {
    switch (type) {
      case "error":
        return Colors.red300;
      case "success":
        return Colors.green200;
    }
  }

  function iconName() {
    switch (type) {
      case "error":
        return "ant-design:exclamation-circle-outlined";
      case "success":
        return "ant-design:check-circle-outlined";
    }
  }

  return (
    <NoticeContainer type={type} maxWidth={maxWidth} marginTop={marginTop}>
      <div>
        {onClose && (
          <a onClick={handleOnCloseClick}>
            <OdcIcon
              name="close"
              size={24}
              color={Colors.white}
              className="close-icon"
            />
          </a>
        )}

        <IconAndContent>
          <IconContainer type={type}>
            <Icon icon={iconName()} />
          </IconContainer>
          <ContentContainer>
            <ModalHeader>
              <Text type="regular-medium" color={headerColor()}>
                {headerText}
              </Text>
            </ModalHeader>
            {messages && messages.length > 0 && (
              <Messages>
                {messages.map((message, index) => (
                  <li key={index}>
                    <Text type="regular-light">{message}</Text>
                  </li>
                ))}
              </Messages>
            )}
            {subMessageText && (
              <SubMessage>
                <Text type="regular-light">{subMessageText}</Text>
              </SubMessage>
            )}
            {resultCodeText && (
              <div className="result-code">
                <Text type="small-light">{resultCodeText}</Text>
              </div>
            )}
            <div className="actions">{renderActions && renderActions()}</div>
          </ContentContainer>
        </IconAndContent>
      </div>
    </NoticeContainer>
  );
}

Notice.propTypes = {
  type: PropTypes.string.isRequired,
  onClose: PropTypes.func.isRequired,
  headerText: PropTypes.string,
  messages: PropTypes.array,
  subMessageText: PropTypes.string,
  resultCodeText: PropTypes.string,
  renderActions: PropTypes.func.isRequired,
  maxWidth: PropTypes.number,
  marginTop: PropTypes.number,
};

export default Notice;
