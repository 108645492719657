import React from "react";
import PropTypes from "prop-types";
import styled, { css } from "styled-components/macro";
import COLORS from "./colorswatches";

const SegmentContainer = styled.div`
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;

  padding: 10px 20px;
  box-shadow: inset 0px 0px 5px ${COLORS.darkGrey400};
  color: ${COLORS.darkGrey500};

  ${({ selected }) => {
    if (selected) {
      return css`
        background-color: ${COLORS.darkGrey100};
        color: ${COLORS.white};
        box-shadow: none;
      `;
    }
  }};
`;

const Segment = ({ selected, children, onClick }) => {
  return (
    <SegmentContainer selected={selected} onClick={onClick}>
      {children}
    </SegmentContainer>
  );
};

Segment.propTypes = {
  selected: PropTypes.bool,
  children: PropTypes.node,
  onClick: PropTypes.func,
};

export default Segment;
