import React from "react";
import useScrollPosition from "../hooks/useScrollPosition";
import ReactDOM from "react-dom/client";
import { Button } from "../odc-ui";

const mountPointId = "mobile-guest-checkout-link-mount-point";

function MobileGuestCheckoutLink() {
  const scrollPosition = useScrollPosition();

  const topOfMountPoint = document.getElementById(mountPointId).offsetTop;
  const windowHeight = window.innerHeight;
  const heightOfButton = 38;
  const buttonPaddingFromEdge = 16;
  const scrollPoint =
    topOfMountPoint - windowHeight + heightOfButton + buttonPaddingFromEdge;

  return (
    <div
      className={`component-mobile-guest-checkout-link sticky-mobile-button ${
        scrollPosition < scrollPoint ? "sticky mobile-container" : "relative"
      }`}
    >
      <Button
        type="light"
        fullWidth
        href="https://www.ottawadermonline.com/shop/healthcoin/32?page=1&limit=180&sort_by=category_order&sort_order=asc"
      >
        Guest Checkout
      </Button>
    </div>
  );
}

export default MobileGuestCheckoutLink;

document.addEventListener("DOMContentLoaded", () => {
  const mountPoint = document.getElementById(mountPointId);

  if (mountPoint) {
    ReactDOM.createRoot(mountPoint).render(<MobileGuestCheckoutLink />);
  }
});
